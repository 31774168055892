<template>
  <div class="page" id="app">
    <div class="editor-container">
      <div class="editor-wrapper">
        <div class="editor">
          <div class="editor-border">
            <div class="title-bar">
              <span>Base64 / Encoded</span>
            </div>
            <textarea id="encoded" class="input" aria-label="encoded" v-model="encoded" />
          </div>
          <button type="button" class="button right" title="Decode (Ctrl + Enter)" @click="decode">
            <SubdirectoryArrowRight :size="16" />
          </button>
        </div>
      </div>
      <div class="editor-wrapper">
        <div class="editor">
          <div class="editor-border">
            <div class="title-bar">
              <span>Plain text / Decoded</span>
            </div>
            <textarea id="decoded" class="input" aria-label="decoded" v-model="decoded" />
          </div>
          <button type="button" class="button left" title="Encode (Ctrl + Enter)" @click="encode">
            <SubdirectoryArrowLeft :size="16" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { encode, decode } from 'js-base64';
import SubdirectoryArrowLeft from 'vue-material-design-icons/SubdirectoryArrowLeft';
import SubdirectoryArrowRight from 'vue-material-design-icons/SubdirectoryArrowRight';

export default {
  name: 'NSoftB64Encoder',
  components: {
    SubdirectoryArrowLeft,
    SubdirectoryArrowRight,
  },
  data() {
    return {
      encoded: '',
      decoded: '',
    };
  },
  mounted() {
    document.querySelector('#encoded').addEventListener('keypress', (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        this.decode();
      }
    });
    document.querySelector('#decoded').addEventListener('keypress', (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        this.encode();
      }
    });
  },
  methods: {
    encode() {
      try {
        this.encoded = encode(this.decoded);
      } catch (error) {
        // Continue
      }
    },
    decode() {
      try {
        this.decoded = decode(this.encoded);
      } catch (error) {
        // Continue
      }
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:200,400,500,600,700');

* {
  padding: 0;
  margin: 0;
}
html {
  font-family: "Roboto", BlinkMacSystemFont, -apple-system, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Arial", sans-serif;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  word-break: normal;
}
.page {
  height: 100vh;
  background-color: #fafafa;
}
.editor-container {
  position: relative;
  height: calc(100% - 32px);
  width: calc(100% - 16px);
  display: flex;
  max-width: 100%;
  padding: 16px 8px;
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
  overflow: hidden;
}
.editor-wrapper {
  position: relative;
  flex-basis: 100%;
  max-width: 100%;
  height: 50%;
}
.editor {
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  position: relative;
  padding: 8px;
  top: 0;
  left: 0;
}
.editor-border {
  border: 1px solid #3883fa;
  height: 100%;
  width: 100%;
}
.title-bar {
  color: #fff;
  display: flex;
  align-items: center;
  background-color: #3883fa;
  height: 24px;
  width: calc(100% - 8px);
  padding: 4px;
  font-weight: 500;
  font-size: 14px;
}
.input {
  width: calc(100% - 8px);
  height: calc(100% - 40px);
  margin: 4px;
  resize: none;
  border: none;
  appearance: none;
  outline: none;
}
.button {
  appearance: none;
  z-index: 4;
  height: 40px;
  width: 40px;
  bottom: -20px;
  margin-bottom: 16px;
  color: #fff;
  background-color: #1976d2;
  border-color: #1976d2;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  position: absolute;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition-duration: .28s;
  transition-property: box-shadow,transform,opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.button.left {
  left: 16px;
}

.button.right {
  right: 16px;
}

.button:hover:before {
  opacity: .08;
}
.button:active:before {
  opacity: .24;
}
.button:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .2s cubic-bezier(.4,0,.6,1);
}
@media (orientation: landscape) {
  .editor-wrapper {
    flex-basis: 50%;
    max-width: 50%;
    height: 100%;
  }
}
</style>
